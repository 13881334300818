import React from 'react';
import './SectionVideosGallery.css'; // Asegúrate de importar el archivo CSS
import video1 from '../../assets/videosComponentes/conservacion.mp4';
import video2 from '../../assets/videosComponentes/caminatas.mp4';
import thumbnail1 from '../../assets/Fotos/thumb01.jpg';
import thumbnail2 from '../../assets/Fotos/thumb02.jpg';


function SectionVideosGallery() {
  return (
    <div className="section-videos-gallery">
      <h1 className="section-videos-gallery__title">Lugar para re-conectar</h1>
      <div className="section-videos-gallery__videos">
        {/* Video 1 */}
        <div className="video-card">
          <video controls className="video-card__video" poster={thumbnail1}>
            <source src={video1} type="video/mp4" />
            Tu navegador no soporta vídeos.
          </video>
          <h2 className="video-card__title">Conservación</h2>
          <p className="video-card__subtitle">El bosque para contemplar de 2 há</p>
        </div>
        
        {/* Video 2 */}
        <div className="video-card">
          <video controls className="video-card__video" poster={thumbnail2}>
            <source src={video2} type="video/mp4" />
            Tu navegador no soporta vídeos.
          </video>
          <h2 className="video-card__title">Senderos para bicicletas y caminatas</h2>
          <p className="video-card__subtitle">Tu parque, tu jardín</p>
        </div>
      </div>
    </div>
  );
  
}

export default SectionVideosGallery;
