// FullScreenModal.js
import React from 'react';
import './FullScreenModal.css'; 

const FullScreenModal = ({ isOpen, onClose, iframeSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="modal full-screen-modal">
      <div className="iframe-container">
        <iframe title="Recorrido Virtual"  src={iframeSrc} frameBorder="0" className="full-screen-iframe"></iframe>
        <button className="iframe-container__close-button" onClick={onClose}>
          <span className="button-text">Cerrar</span>
        </button>
      </div>
    </div>
  );
};

export default FullScreenModal;
