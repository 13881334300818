// Header.js
import React from 'react';
import './Header.css'; // Asegúrate de que la ruta al archivo CSS es correcta
import logo from '../../assets/logo/logo-molco-vivo-blanco.png'; // Reemplaza con la ruta correcta a tu imagen de logo
import logo1 from '../../assets/logo/logov3.png'; // Reemplaza con la ruta correcta a tu imagen de logo

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import backgroundVideo from '../../assets/videosComponentes/videobkg1080p.mp4'; // Asegúrate de reemplazar con la ruta correcta a tu archivo de video

const Header = () => {
  return (
    <header className="header">
      <nav className="header__navbar">
        <div className="header__logo-container">
          <img src={logo1} alt="Logo Molco Vivo" className="header__logo" />
        </div>
        <div className="header__location-container">
          <div className="header__gps-icon-container">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="header__gps-icon" />
          </div>
          <p className="header__location-text"><b>MOLCO</b><br/>Villarrica y Pucón</p>
        </div>
      </nav>
      <div className="header__hero-container">
      <video
  autoPlay
  loop
  muted
  playsInline
  className="header__background-video"
  poster="../../assets/Fotos/volcan-villarrica-02.jpg" // Imagen de fondo mientras el video está cargando
>
  <source src={backgroundVideo} type="video/mp4" />
  Tu navegador no soporta la etiqueta de video.
</video>
        <div className="header__content">
          <img src={logo} alt="Logo Molco Vivo Blanco" className="header__logo-white" />
          <h1 className="header__title">Es tiempo para una <br></br>nueva aventura</h1>
          <h2 className="header__subtitle">una nueva forma de vivir</h2>
          <button className="header__button">CONVERSEMOS</button>
        </div>
      </div>
    </header>
  );
};

export default Header;

