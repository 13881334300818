import React from 'react';
import './SectionNatureSpace.css';

// Importación de imágenes (ajusta las rutas según tu proyecto)
import familyImage from '../../assets/Fotos/family.jpg';
import workImage from '../../assets/Fotos/work.jpg';
import tourismImage from '../../assets/Fotos/tourism.webp';

const SectionGallery = () => {
  return (
    <section className="section-gallery">
      <h1 className="section-gallery__title">Nuestra naturaleza y espacios de encuentro</h1>

      <div className="section-gallery__cards">
        {/* Tarjeta 1 */}
        <div className="section-gallery__card">
          <img src={familyImage} alt="Vivir en familia" className="section-gallery__card-image" />
          <h2 className="section-gallery__card-title">Vivir en familia</h2>
          <p className="section-gallery__card-subtitle">Un mundo en armonía con la naturaleza</p>
        </div>

        {/* Tarjeta 2 */}
        <div className="section-gallery__card">
          <img src={workImage} alt="Trabaja Online" className="section-gallery__card-image" />
          <h2 className="section-gallery__card-title">Trabaja Online</h2>
          <p className="section-gallery__card-subtitle">Comparte Offline</p>
        </div>

        {/* Tarjeta 3 */}
        <div className="section-gallery__card">
          <img src={tourismImage} alt="Pucon-Villarrica" className="section-gallery__card-image" />
          <h2 className="section-gallery__card-title">Pucon-Villarrica</h2>
          <p className="section-gallery__card-subtitle">Turismo outdoor</p>
        </div>
      </div>
    </section>
  );
};

export default SectionGallery;
