
import './App.css';
import Header from './components/Header/Header';
import SectionWhy from './components/SectionWhy/SectionWhy';
import SectionFeatures from './components/SectionFeatures/SectionFeatures';
import SectionVideosGallery from './components/SectionVideosGallery/SectionVideosGallery';
import SectionNatureSpace from './components/SectionNatureSpace/SectionNatureSpace';
import SectionPillars from './components/SectionPillars/SectionPillars';
import LocationSection from './components/SectionLocation/SectionLocation';
import SectionContact from './components/SectionContact/SectionContact';
import MasterPlan360 from './components/SectionMasterPlan/SectionMasterPlan';
function App() {
  return (
    <div className="App">
     <Header/> 
     <SectionWhy/>
     <SectionFeatures />
     <SectionVideosGallery />
     <SectionNatureSpace/>
     <SectionPillars/>
     <LocationSection/>
     <MasterPlan360/>
     <SectionContact/>
     
     
    </div>
  );
}

export default App;
