// SectionFeatures.js
import React from 'react';
import './SectionFeatures.css';
import image1 from '../../assets/Fotos/features-01.jpg';
import image2 from '../../assets/Fotos/features-02.jpg';
import image3 from '../../assets/Fotos/features-03.jpg';

// ... resto del componente



// Dentro de tu componente SectionFeatures.js
const SectionFeatures = () => {
  const cardData = [
    {
      id: 1,
      image: image1,
      title: 'Descubre un mundo',
      subtitle: 'en armonía con la naturaleza',
      listItems: ['Concepto de condominio para la seguridad y administración del proyecto.', 'Reglamento interno de vecinos.', '2km. de senderos rodean el proyecto para caminar, trotar y pasear en bicicleta.','Distribución de luz a cada parcela de 9.9kw.']
    },
    {
      id: 2,
      image: image2,
      title: 'Con áreas de',
      subtitle: 'conservación',
      listItems: ['35 parcelas exclusivas con roles inscritos.',
       '2 ha. de Bosque Nativo con senderos y mirador al lago Villarrica.', 
       ' Ubicación estratégica en el sur de Chile, de gran valor turístico y alta plusvalía.' ,
       'Suministro de agua con presión garantizada.'
      ]

      


    },
    {
      id: 3,
      image: image3,
      title: 'A sólo 25 minutos',
      subtitle: 'de Pucón y Villarrica',
      listItems: ['Proyecto de facil acceso por camino ripiado.', 'A sólo 25 minutos de Pucón y Villarrica.', '18 minutos del lago Villarrica.','40 minutos del nuevo centro de esquí Pillán en Pucón.']
      
      
      
      

    },
    // ... más tarjetas si las hay
  ];

  return (
  <div className="section-featuresContainer">
    <h1 className="section-features__title">Más detalles</h1>
    
    <div className="section-features">
      
      {cardData.map((card) => (
        
        <div key={card.id} className="section-features__card">
          <div className="card__image-container">
            <img src={card.image} alt={card.title} className="card__image" />
            <h2 className="card__title">{card.title}</h2>
            <h3 className="card__subtitle">{card.subtitle}</h3>
          </div>
          <ul className="card__list">
            {card.listItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
  );
};

export default SectionFeatures;
