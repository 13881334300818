import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import './SectionWhy.css';
import volcanoImage from '../../assets/Fotos/Volcan-villarrica-01.jpg';
import FullScreenModal from '../ModalTourVirtual/FullScreenModal';

const SectionWhy = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="SectionWhy">
      <div className="SectionWhy__column SectionWhy__column--first">
        <div className="SectionWhy__icon-container">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="SectionWhy__icon" />
        </div>
        <h1 className="SectionWhy__title">¿Por qué Molco Vivo?</h1>
        <p className="SectionWhy__description">
          Molco Vivo es el lugar ideal para vivir cerca de Pucón y Villarrica, conectado a la naturaleza y a una nueva forma de vivir más consciente:
        </p>
        <ul className="SectionWhy__list">
          <li>Zona de conservación de bosque nativo y naturaleza.</li> <br></br>
          <li>Suministro de agua consciente y responsable.</li><br></br>
          <li>Alto estándar de seguridad eléctrica y servicios básicos.</li><br></br>
          <li>Conciencia del impacto medioambiental.</li><br></br>
          <li>Espacios online y offline para deporte y el encuentro.</li><br></br>
          <li>Fácil acceso y terrenos planos de fácil construcción.</li>
        </ul>
        <button 
          className="SectionWhy__button"
          onClick={() => setModalOpen(true)} // Lógica para abrir el modal
        >
          MASTERPLAN
        </button>
        {/* ...otros botones... */}
      </div>
      <div className="SectionWhy__column SectionWhy__column--second">
        <img src={volcanoImage} alt="Volcan Villarrica" className="SectionWhy__image" />
      </div>

      <FullScreenModal 
        isOpen={isModalOpen} 
        onClose={() => setModalOpen(false)} 
        iframeSrc="https://masterplan360.s3.sa-east-1.amazonaws.com/MolcoVivo/index.html" // Reemplaza con la URL de tu recorrido virtual
      />
    </div>
  );
};

export default SectionWhy;
