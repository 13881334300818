// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-gallery {
    background-color: #c4cdc6;
    padding: 40px;
  }
  
  .section-gallery__title {
    color: #231f20;
    text-align: center;
    font-size: calc(1.5em + 1vw);
    margin-bottom: 30px;
  }
  
  .section-gallery__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: auto;
    
  }
  
  .section-gallery__card {
    flex-basis: calc(33% - 20px); /* Ancho de cada tarjeta */
    margin: 22px;
    max-width: 330px;
  }
  
  .section-gallery__card-image {
    width: 100%;
    height: auto;
    aspect-ratio: 2 / 3;
    object-fit: cover;
  }
  
  .section-gallery__card-title,
  .section-gallery__card-subtitle {
    color: #231f20;
    text-align: center;
    margin: 5px 0;
  }
  
  .section-gallery__card-title {
    font-size: calc(1em + 1vw);
  }
  
  .section-gallery__card-subtitle {
    font-size: calc(0.8em + 0.5vw);
  }
  
  /* Responsive para dispositivos móviles */
  @media (max-width: 600px) {
    .section-gallery__cards {
      flex-direction: column;
    }
  
    .section-gallery__card {
      flex-basis: 100%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SectionNatureSpace/SectionNatureSpace.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,4BAA4B;IAC5B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;IACjB,YAAY;;EAEd;;EAEA;IACE,4BAA4B,EAAE,0BAA0B;IACxD,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA;;IAEE,cAAc;IACd,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,8BAA8B;EAChC;;EAEA,yCAAyC;EACzC;IACE;MACE,sBAAsB;IACxB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".section-gallery {\n    background-color: #c4cdc6;\n    padding: 40px;\n  }\n  \n  .section-gallery__title {\n    color: #231f20;\n    text-align: center;\n    font-size: calc(1.5em + 1vw);\n    margin-bottom: 30px;\n  }\n  \n  .section-gallery__cards {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    max-width: 1200px;\n    margin: auto;\n    \n  }\n  \n  .section-gallery__card {\n    flex-basis: calc(33% - 20px); /* Ancho de cada tarjeta */\n    margin: 22px;\n    max-width: 330px;\n  }\n  \n  .section-gallery__card-image {\n    width: 100%;\n    height: auto;\n    aspect-ratio: 2 / 3;\n    object-fit: cover;\n  }\n  \n  .section-gallery__card-title,\n  .section-gallery__card-subtitle {\n    color: #231f20;\n    text-align: center;\n    margin: 5px 0;\n  }\n  \n  .section-gallery__card-title {\n    font-size: calc(1em + 1vw);\n  }\n  \n  .section-gallery__card-subtitle {\n    font-size: calc(0.8em + 0.5vw);\n  }\n  \n  /* Responsive para dispositivos móviles */\n  @media (max-width: 600px) {\n    .section-gallery__cards {\n      flex-direction: column;\n    }\n  \n    .section-gallery__card {\n      flex-basis: 100%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
