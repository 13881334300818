import React, { useState } from 'react';
import './ContactForm.css';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Lógica para enviar los datos del formulario
        console.log('Enviando', { name, email, phone, message });
    };

    return (
        <div className="contact-form">
            <h2 className="contact-form__title">Contacto</h2>
            <form className="contact-form__form" onSubmit={handleSubmit}>
                <div className="contact-form__group">
                    <input 
                        type="text" 
                        className="contact-form__input" 
                        placeholder="Nombre" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                    />
                </div>
                <div className="contact-form__group">
                    <input 
                        type="email" 
                        className="contact-form__input" 
                        placeholder="Correo" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                    />
                </div>
                <div className="contact-form__group">
                    <input 
                        type="tel" 
                        className="contact-form__input" 
                        placeholder="Teléfono" 
                        value={phone} 
                        onChange={(e) => setPhone(e.target.value)} 
                    />
                </div>
                <div className="contact-form__group">
                    <textarea 
                        className="contact-form__textarea" 
                        placeholder="Mensaje" 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                    ></textarea>
                </div>
                <button className="contact-form__button" type="submit">Enviar</button>
            </form>
        </div>
    );
};

export default ContactForm;
