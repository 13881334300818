import React from 'react';
import './MapComponent.css';

const MapComponent = () => {
  return (
    <div className="map-component">
      <iframe title="Molco Vivo - Pucón"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3084.7698957894627!2d-72.07026342404077!3d-39.36144717162874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x96146336b70bc729%3A0x297ea4acee12db25!2sMolco%20Vivo!5e0!3m2!1ses-419!2scl!4v1700085754210!5m2!1ses-419!2scl"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default MapComponent;
