// MasterPlan360.js
import React, { useState } from 'react';
import FullScreenModal from '../ModalTourVirtual/FullScreenModal'; // Asegúrate de que la ruta sea correcta
import './SectionMasterPlan.css';

const MasterPlan360 = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    return (
        <div className="masterplan-section">
            <h2 className="masterplan-title">Master plan 360</h2>
            <iframe 
                title='Loteo Molco vivo, Pucón'
                className="masterplan-iframe" 
                src="https://masterplan360.s3.sa-east-1.amazonaws.com/MolcoVivo/index.html"
                width="80%"
               
                frameBorder="0"
                allowFullScreen
            ></iframe>
            <button 
                className="masterplan-button" // Utiliza los mismos estilos que el botón de SectionWhy
                onClick={() => setModalOpen(true)}
                style={{ margin: '30px auto', display: 'block' }} // Inline styles para centrar el botón y añadir margen
            >
                Pantalla completa
            </button>
            <FullScreenModal 
                isOpen={isModalOpen} 
                onClose={() => setModalOpen(false)} 
                iframeSrc="https://masterplan360.s3.sa-east-1.amazonaws.com/MolcoVivo/index.html" 
            />
        </div>
    );
}

export default MasterPlan360;
