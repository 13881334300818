// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.section-videos-gallery__title {
  color: #231f20;
  text-align: center;
  font-size: calc(1.5em + 1vw);
  margin-bottom: 30px;
    
  }
  
  .video-card__title, .video-card__subtitle {
    font-size: calc(1vw + 10px);
    color: #231f20;
  }
  .section-videos-gallery {
    background-color: #c4cdc6;
    padding: 40px;
  }
  
  .section-videos-gallery__videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .video-card {
    width: 100%;
    max-width: 600px;
    background-color: transparent;
  }
  
  .video-card__video {
    width: 100%;
    height: auto;
  }
  @media (max-width: 768px) {
    .section-videos-gallery__videos {
      flex-direction: column;
      align-items: center;
    }
    .video-card__title{
      font-size: 20px;
    }
    .video-card__subtitle {

      font-size: 16px;
    }

    .section-videos-gallery__title {
        font-size: 30px;
    }
  }
      `, "",{"version":3,"sources":["webpack://./src/components/SectionVideosGallery/SectionVideosGallery.css"],"names":[],"mappings":";;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,4BAA4B;EAC5B,mBAAmB;;EAEnB;;EAEA;IACE,2BAA2B;IAC3B,cAAc;EAChB;EACA;IACE,yBAAyB;IACzB,aAAa;EACf;;EAEA;IACE,aAAa;IACb,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,6BAA6B;EAC/B;;EAEA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE;MACE,sBAAsB;MACtB,mBAAmB;IACrB;IACA;MACE,eAAe;IACjB;IACA;;MAEE,eAAe;IACjB;;IAEA;QACI,eAAe;IACnB;EACF","sourcesContent":["\n\n.section-videos-gallery__title {\n  color: #231f20;\n  text-align: center;\n  font-size: calc(1.5em + 1vw);\n  margin-bottom: 30px;\n    \n  }\n  \n  .video-card__title, .video-card__subtitle {\n    font-size: calc(1vw + 10px);\n    color: #231f20;\n  }\n  .section-videos-gallery {\n    background-color: #c4cdc6;\n    padding: 40px;\n  }\n  \n  .section-videos-gallery__videos {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n  }\n  \n  .video-card {\n    width: 100%;\n    max-width: 600px;\n    background-color: transparent;\n  }\n  \n  .video-card__video {\n    width: 100%;\n    height: auto;\n  }\n  @media (max-width: 768px) {\n    .section-videos-gallery__videos {\n      flex-direction: column;\n      align-items: center;\n    }\n    .video-card__title{\n      font-size: 20px;\n    }\n    .video-card__subtitle {\n\n      font-size: 16px;\n    }\n\n    .section-videos-gallery__title {\n        font-size: 30px;\n    }\n  }\n      "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
