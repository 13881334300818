// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-component {
    /* Ajusta el tamaño y otros estilos del mapa según necesites */
    width: 100%;
   height: 100%;
    overflow: hidden;
  }
  
  .map-component iframe {
    width: 100%;
    height: 100%;
    border: none; /* Elimina el borde del iframe para un look más limpio */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/SectionLocation/MapComponent.css"],"names":[],"mappings":"AAAA;IACI,8DAA8D;IAC9D,WAAW;GACZ,YAAY;IACX,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,YAAY,EAAE,wDAAwD;EACxE","sourcesContent":[".map-component {\n    /* Ajusta el tamaño y otros estilos del mapa según necesites */\n    width: 100%;\n   height: 100%;\n    overflow: hidden;\n  }\n  \n  .map-component iframe {\n    width: 100%;\n    height: 100%;\n    border: none; /* Elimina el borde del iframe para un look más limpio */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
