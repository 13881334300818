import React from 'react';
import ContactForm from './ContactForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo/logov3.png'; // Reemplaza con la ruta correcta del logo
import backgroundImg from '../../assets/Fotos/caminos.webp'; // Reemplaza con la ruta correcta de la imagen de fondo
import './SectionContact.css';


const SectionContact = () => {
    return (
        <section className="section-contact" style={{ backgroundColor: '#c4cdc6' }}>
            <div className="section-contact__info" style={{ backgroundImage: `url(${backgroundImg})` }}>
                <img src={logo} alt="Logo" className="section-contact__logo"/>
                <div className="section-contact__location-container">
                    <div className="section-contact__gps-icon-container">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="section-contact__gps-icon" />
                    </div>
                    <p className="section-contact__location-text"><b>MOLCO VIVO</b><br/>Villarrica y Pucón</p>
                </div>
                <h2 className="section-contact__title">Reservemos tu visita</h2>
            </div>
            <div className="section-contact__form">
                <ContactForm />
            </div>
        </section>
    );
};

export default SectionContact;
