// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.masterplan-section {
  
    padding: 40px;
    text-align: center;
}


.masterplan-iframe {
    max-width: 80%;
    height: 600px;
    border: none; /* Para eliminar el borde del iframe */
    /* Ajustes adicionales si son necesarios */
}
.masterplan-button{
background-color: #202020;
    color: #fdf6ea;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    padding: 11px 35px;
    border: none;
    margin: 10px;
    transition: background-color 0.3s ease;
    font-size: calc(.07vw + 18px);
    border-radius:calc(1vw + 20px) ;
    min-width: 220px;
}

.masterplan-title{
    font-size: calc(1vw + 32px);
    margin-bottom: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/components/SectionMasterPlan/SectionMasterPlan.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,kBAAkB;AACtB;;;AAGA;IACI,cAAc;IACd,aAAa;IACb,YAAY,EAAE,sCAAsC;IACpD,0CAA0C;AAC9C;AACA;AACA,yBAAyB;IACrB,cAAc;IACd,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,sCAAsC;IACtC,6BAA6B;IAC7B,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,mBAAmB;EACrB","sourcesContent":[".masterplan-section {\n  \n    padding: 40px;\n    text-align: center;\n}\n\n\n.masterplan-iframe {\n    max-width: 80%;\n    height: 600px;\n    border: none; /* Para eliminar el borde del iframe */\n    /* Ajustes adicionales si son necesarios */\n}\n.masterplan-button{\nbackground-color: #202020;\n    color: #fdf6ea;\n    text-decoration: underline;\n    font-weight: 600;\n    cursor: pointer;\n    padding: 11px 35px;\n    border: none;\n    margin: 10px;\n    transition: background-color 0.3s ease;\n    font-size: calc(.07vw + 18px);\n    border-radius:calc(1vw + 20px) ;\n    min-width: 220px;\n}\n\n.masterplan-title{\n    font-size: calc(1vw + 32px);\n    margin-bottom: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
