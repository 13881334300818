import React from 'react';
import './SectionPillars.css';

// Importación de imágenes (ajusta las rutas según tu proyecto)
import ciclismoImage from '../../assets/pillars/ciclismo.webp';
import compostajeImage from '../../assets/pillars/compostaje.webp';
import huertoImage from '../../assets/pillars/huerto.webp';
import miradoresImage from '../../assets/pillars/miradores.jpg';
import caminosImage from '../../assets/pillars/caminos.webp';
import casaAdminImage from '../../assets/pillars/administracion.webp';
import retencionAguaImage from '../../assets/pillars/retencion.webp';
import viveroNativoImage from '../../assets/pillars/vivero.webp';
import areaConservacionImage from '../../assets/pillars/conservacion.webp';
import senderosTrekkingImage from '../../assets/pillars/senderos.webp';

const SectionPillars = () => {
    const pillarsData = [
        { image: ciclismoImage, title: "Ciclismo" },
        { image: compostajeImage, title: "Compostaje" },
        { image: huertoImage, title: "Huerto" },
        { image: miradoresImage, title: "Miradores" },
        { image: caminosImage, title: "Caminos" },
        { image: casaAdminImage, title: "Casa de Administración" },
        { image: retencionAguaImage, title: "Retención de Agua" },
        { image: viveroNativoImage, title: "Vivero Nativo" },
        { image: areaConservacionImage, title: "Área de Conservación" },
        { image: senderosTrekkingImage, title: "Senderos de Trekking" }
      ];
      

  return (
    <section className="section-pillars">
        <h1 className='section-pillars__title'> Nuestros Pilares</h1>
      <div className="section-pillars__container">
        
        {pillarsData.map((pillar, index) => (
          <div key={index} className="section-pillars__card">
            <img src={pillar.image} alt={pillar.title} className="section-pillars__card-image" />
            <h2 className="section-pillars__card-title">{pillar.title}</h2>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SectionPillars;
