import React from 'react';
import MapComponent from './MapComponent';

import './SectionLocation.css';

const SectionLocation = () => {
  return (
    <div className="section-location">
    <h2 className="section-location__title">Ubicación</h2>
    <div className="section-location__content">
        <div className="section-location__map">
            <MapComponent />
        </div>
        <div className="section-location__details">
           
            <ul className="section-location__list">
                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 25</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Pucón
                </li>

                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 25</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Villarrica
                </li>

                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 20</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Colegio Alemán
                </li>
        
                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 15</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Mercado Local
                </li>

            
                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 20</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Hospital
                </li>

                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 25</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Parque Nacional Villarrica
                </li>
            
                <li className="section-location__item">
                    <div className="section-location__time-circle">
                    <span className="section-location__time"> 40</span>
                    <span className="section-location__time-min">min</span>
                    </div>
                    Centro de Ski
                </li>
            
            </ul>
        
        </div>
        </div>
        </div>
  );
};

export default SectionLocation;
