// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Variables de colores */
:root {

  
  --color-primary: #283324; /* Color de fondo por defecto */
  --color-secondary: #bfc5bf; /* Color de fondo por defecto */
  --color-accent1: #ffffff;/* Color de fondo del footer */
  --color-accent2: #afca0e;
  --color-contrast: #bcc9a1;
  --color-text: #202020;

  /* ... puedes agregar más colores según tu paleta de diseño */
}


/* Aplicar Roboto y Roboto Condensed en tus estilos */
body {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3 {
  font-family: 'Roboto Condensed', sans-serif;
}

/* Asegúrate de especificar el estilo y el peso para elementos que deben ser cursiva o negrita */
.italic {
  font-style: italic;
}

.bold {
  font-weight: 700; /* 700 es el peso de la fuente para negrita */
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA,yBAAyB;AACzB;;;EAGE,wBAAwB,EAAE,+BAA+B;EACzD,0BAA0B,EAAE,+BAA+B;EAC3D,wBAAwB,CAAC,8BAA8B;EACvD,wBAAwB;EACxB,yBAAyB;EACzB,qBAAqB;;EAErB,6DAA6D;AAC/D;;;AAGA,qDAAqD;AACrD;EACE,iCAAiC;AACnC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA,gGAAgG;AAChG;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB,EAAE,6CAA6C;AACjE","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n/* Variables de colores */\n:root {\n\n  \n  --color-primary: #283324; /* Color de fondo por defecto */\n  --color-secondary: #bfc5bf; /* Color de fondo por defecto */\n  --color-accent1: #ffffff;/* Color de fondo del footer */\n  --color-accent2: #afca0e;\n  --color-contrast: #bcc9a1;\n  --color-text: #202020;\n\n  /* ... puedes agregar más colores según tu paleta de diseño */\n}\n\n\n/* Aplicar Roboto y Roboto Condensed en tus estilos */\nbody {\n  font-family: 'Roboto', sans-serif;\n}\n\nh1, h2, h3 {\n  font-family: 'Roboto Condensed', sans-serif;\n}\n\n/* Asegúrate de especificar el estilo y el peso para elementos que deben ser cursiva o negrita */\n.italic {\n  font-style: italic;\n}\n\n.bold {\n  font-weight: 700; /* 700 es el peso de la fuente para negrita */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
