// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
    max-width: 380px;
    margin: auto;
    color: #283324;
}

.contact-form__title {
    text-align: center;
}

.contact-form__group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.contact-form__group:not(:last-child)::after {
    content: "";
    height: 2px;
    background-color: #ffffff;
    margin-top: 10px;
}

.contact-form__input, .contact-form__textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form__button {
    background-color: #202020;
    color: #fdf6ea;
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    padding: 11px 35px;
    border: none;
    margin: 10px;
    transition: background-color 0.3s ease;
    font-size: calc(.07vw + 18px);
    border-radius: calc(1vw + 20px);
}

.contact-form__button:hover {
    background-color: #383838; /* Ligeramente más claro al pasar el ratón por encima */
}
`, "",{"version":3,"sources":["webpack://./src/components/SectionContact/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,0BAA0B;IAC1B,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,sCAAsC;IACtC,6BAA6B;IAC7B,+BAA+B;AACnC;;AAEA;IACI,yBAAyB,EAAE,uDAAuD;AACtF","sourcesContent":[".contact-form {\n    max-width: 380px;\n    margin: auto;\n    color: #283324;\n}\n\n.contact-form__title {\n    text-align: center;\n}\n\n.contact-form__group {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n\n.contact-form__group:not(:last-child)::after {\n    content: \"\";\n    height: 2px;\n    background-color: #ffffff;\n    margin-top: 10px;\n}\n\n.contact-form__input, .contact-form__textarea {\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.contact-form__button {\n    background-color: #202020;\n    color: #fdf6ea;\n    text-decoration: underline;\n    font-weight: 600;\n    cursor: pointer;\n    padding: 11px 35px;\n    border: none;\n    margin: 10px;\n    transition: background-color 0.3s ease;\n    font-size: calc(.07vw + 18px);\n    border-radius: calc(1vw + 20px);\n}\n\n.contact-form__button:hover {\n    background-color: #383838; /* Ligeramente más claro al pasar el ratón por encima */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
